import { PaymentMethodTypeEnum } from '@goparrot/common';
import { UserTypeEnum } from '@goparrot/customer-sdk';
import type { IReadSquareCardMetadataDto, PaymentSourceDto, ReadPaymentCardDto } from '@goparrot/payment-sdk';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { CloseIcon, CreditCardIcon } from '@webstore-monorepo/shared/icons';
import { platformAlert } from '@webstore-monorepo/shared/utils/alert';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Collapsable } from '@webstore-monorepo/ui/collapsable';
import { Select } from '@webstore-monorepo/ui/select';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React, { useState } from 'react';
import { Pressable } from 'react-native';
import type { ValueType } from 'react-select';

import { useRemoveCardMutation } from '../../../api/mutations/use-store-card-mutation';
import { useOrder, useOrderDispatch } from '../../../contexts/order-provider';
import { useGetClientCards } from '../../../queries/use-get-client-cards';
import { AddNewCreditCard } from './AddNewCreditCard';
import { CreditCardForm } from './CreditCardForm';

type CreditCardType = {
  isPaymentLoading?: boolean;
};
export const CreditCard: FC<CreditCardType> = ({ isPaymentLoading }) => {
  const { analytics } = usePlatformStoreState();
  const { user } = useUserContextState();
  const [isVisible, setIsVisible] = useState(false);
  const [selectedCard, setSelectedCard] = useState<PaymentSourceDto | undefined>(undefined);
  const [cards, setCards] = useState<PaymentSourceDto[]>([]);
  const { onSelectPaymentType, onSetSquareCard, onSaveCardToUser, onShouldSaveCardToggle } = useOrderDispatch();
  const { selectedPaymentType } = useOrder();
  const isCardSelected = selectedPaymentType?.type === PaymentMethodTypeEnum.CREDIT_CARD;

  const { data: userCards, isLoading } = useGetClientCards({
    enabled: user.type === UserTypeEnum.AUTHENTICATED,
    onSuccess: async (data) => {
      const mappedCards = data.map((item) => ({
        id: (item.metadata as IReadSquareCardMetadataDto).cardId,
        uuid: item.uuid,
        type: PaymentMethodTypeEnum.CREDIT_CARD,
        last4Digits: item.last4Digits,
        cardNonce: (item.metadata as IReadSquareCardMetadataDto).cardNonce,
        brand: (item.metadata as IReadSquareCardMetadataDto).cardBrand,
      }));

      setCards(mappedCards);
      setSelectedCard(mappedCards[0]);
      analytics.track('payment_card_list', { cards: mappedCards });
      onSelectPaymentType({
        ...(mappedCards[0] ?? { type: PaymentMethodTypeEnum.CREDIT_CARD }),
      });
    },
  });

  const { mutate: handleRemoveUserCard } = useRemoveCardMutation({
    onError: (error) => {
      analytics.track('payment_card_remove_error', { error });
    },
  });

  const handleSelectPaymentType = async (card?: PaymentSourceDto) => {
    if (card) {
      const payment: PaymentSourceDto = {
        type: PaymentMethodTypeEnum.CREDIT_CARD,
        last4Digits: card.last4Digits,
        brand: card.brand,
        cardNonce: card.cardNonce ?? card.id,
      };
      if (card.uuid) {
        payment.uuid = card.uuid;
      }

      onSelectPaymentType({ ...payment });
    }
  };

  const handleCloseModal = () => {
    setIsVisible(false);
  };

  const handleAddNewCard = () => {
    setIsVisible(true);
  };

  const handleClearSelectedCard = () => {
    platformAlert({
      confirmMessage: 'Are you sure you want to remove this credit card?',
      onConfirm: () => {
        // check if the card is already saved in the user's account
        if (selectedCard?.uuid && userCards?.find((item) => item.uuid === selectedCard?.uuid)) {
          handleRemoveUserCard(selectedCard?.uuid);
        }
        // if the card is not saved in the user account, just filter it out from the list
        const newCards = cards.filter((item) => item.uuid !== selectedCard?.uuid);
        setCards(newCards);
        handleSetSelectedCard(newCards[0]);
      },
      onCancel: () => {},
    });
  };

  const handleSetSelectedCard = (value: ValueType<PaymentSourceDto>) => {
    setSelectedCard(value as PaymentSourceDto);
    handleSelectPaymentType(value as PaymentSourceDto);
  };

  const handleSaveCardToUse = (card: PaymentSourceDto) => {
    if (!cards.find((item) => item.last4Digits === card.last4Digits)) {
      setCards((prev) => [...prev, card]);
      handleSetSelectedCard(card);
    }
  };

  const handleToggleCollapse = (isOpen: boolean) => {
    if (cards.length === 0 && !isOpen) {
      onSetSquareCard(undefined);
      return;
    }
    onSelectPaymentType(isOpen ? (cards.length > 0 ? { ...cards[0] } : { type: PaymentMethodTypeEnum.CREDIT_CARD }) : undefined);
  };

  const handleSaveCard = async () => {
    const card = await onSaveCardToUser();
    if (card && Object.keys(card).includes('lastUsed')) {
      handleSaveCardToUse({
        type: PaymentMethodTypeEnum.CREDIT_CARD,
        brand: ((card as ReadPaymentCardDto).metadata as IReadSquareCardMetadataDto).cardBrand,
        last4Digits: card.last4Digits,
        cardNonce: ((card as ReadPaymentCardDto).metadata as IReadSquareCardMetadataDto).cardId,
      });
    } else {
      handleSaveCardToUse(card as PaymentSourceDto);
    }
    onShouldSaveCardToggle();
    handleCloseModal();
  };

  return (
    <Box
      paddingTop={6}
      paddingBottom={6}
      paddingLeft={4}
      paddingRight={4}
      borderColor={isCardSelected ? 'primary' : 'gray200'}
      borderWidth={1}
      borderRadius="md"
      zIndex={100}
      testID="checkout-credit-card-section"
    >
      <Collapsable
        showIcon={false}
        title={
          <Box flexDirection="row" alignItems="center" justifyContent="center">
            <Box flexGrow={1}>
              <Text fontWeight="500" lineHeight={24} fontSize="m" color="gray900">
                Credit Card
              </Text>
            </Box>
            <CreditCardIcon fill="gray900" />
          </Box>
        }
        onToggleCollapse={handleToggleCollapse}
        isDefaultOpen={isCardSelected}
      >
        <Box marginTop={6} alignItems="flex-start">
          {cards.length > 0 ? (
            <>
              <Box zIndex={100} width="100%">
                <Select
                  key={cards.length}
                  isClearable={false}
                  isSearchable={false}
                  isLoading={isLoading}
                  options={cards}
                  maxMenuHeight={180}
                  value={selectedCard}
                  onChange={handleSetSelectedCard}
                  getOptionLabel={(option) => `**** **** **** ${option.last4Digits}`}
                  getOptionValue={(option) => option.uuid ?? ''}
                  placeholder="Credit cards"
                  className="gp-select"
                  classNamePrefix="gp-select"
                  data-testId="checkout-credit-card-select"
                  styles={{ control: (base) => ({ ...base, height: 56 }), indicatorSeparator: () => ({ display: 'none' }) }}
                />
                {cards.length > 0 && selectedCard ? (
                  <Pressable onPress={handleClearSelectedCard} style={{ position: 'absolute', top: '50%', right: 36 }}>
                    <CloseIcon width={12} height={12} />
                  </Pressable>
                ) : null}
              </Box>
              <Box marginTop={4}>
                <Button isLoading={isPaymentLoading} onPress={handleAddNewCard} testID="checkout-add-cc-button">
                  Add new credit card
                </Button>
              </Box>
            </>
          ) : (
            <Box width="100%">
              <CreditCardForm />
            </Box>
          )}
        </Box>
      </Collapsable>
      <AddNewCreditCard isVisible={isVisible} onSaveCard={handleSaveCard} onClose={handleCloseModal}>
        {isVisible ? <CreditCardForm /> : null}
      </AddNewCreditCard>
    </Box>
  );
};
